import React from "react"
import { Link } from "gatsby"
import { ParallaxBanner } from "react-scroll-parallax"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Wrapper from "../components/wrapper"
import GridItem from "../components/griditem"
import GameItem from "../components/gameitem"

const SecondPage = () => (
  <Layout>
    <SEO title="Widerrufsrecht" />
    <ParallaxBanner
      className="your-class"
      layers={[
        {
          image:
            "https://images.unsplash.com/photo-1564049489314-60d154ff107d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2561&q=80",
          amount: 0.2,
        },
      ]}
      style={{
        height: "200px",
      }}
    ></ParallaxBanner>
    <Wrapper padding>
      <h2 style={{ textAlign: "center", fontWeight: 500, width: "100%" }}>
        Widerrufsrecht
      </h2>
      <ul class="portfolioPrev">
        <br />
        Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen
        diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage
        ab dem Tag des Vertragsabschlusses. Um Ihr Widerrufsrecht auszuüben,
        müssen Sie uns mittels einer eindeutigen Erklärung (z. B. ein mit der
        Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag
        zu widerrufen, informieren. Bitte verwenden Sie dafür das folgende
        Muster-Widerrufsformular. Zur Wahrung der Widerrufsfrist reicht es aus,
        dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf
        der Widerrufsfrist absenden.
        <br />
        <br />
        <br />
        Musterwiderrufsformular:
        <br />
        <br />
        (wenn sie den Vertrag widerrufen wollen, dann füllen sie bitte dieses
        Formular aus und senden Sie es zurück)
        <br />
        <br />
        -An:
        <br />
        Brain Offices GmbH <br />
        Mühlenstr. 8 a <br />
        D-14167 Berlin <br />
        Deutschland
        <br />
        info@care-tech.info
        <br />
        <br />
        - Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen
        Vertrag über den Kauf der folgenden Waren (*)/die Erbringung der
        folgenden Dienstleistung (*)
        <br />
        <br />
        - Bestellt am (*)/erhalten am (*)
        <br />
        <br />
        - Name des/der Verbraucher(s)
        <br />
        <br />
        - Anschrift des/der Verbraucher(s)
        <br />
        <br />
        - Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
        <br />
        <br />
        - Datum
        <br />
        <br />
        (*) Unzutreffendes streichen.
        <br />
        <br />
        Folgen des Widerrufs
        <br />
        <br />
        Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die
        wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit
        Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine
        andere Art der Lieferung als die von uns angebotene, günstigste
        Standardlieferung gewählt haben), unverzüglich und spätestens binnen
        vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über
        Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese
        Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der
        ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen
        wurde ausdrücklich etwas anderes vereinbart. In keinem Fall werden Ihnen
        wegen dieser Rückzahlung Entgelte berechnet.
        <br />
        <br />
        Sie verlieren jedoch Ihr Rücktrittsrecht wenn
        <br />
        <br />
        a. der mit uns abgeschlossene Vertrag die Lieferung von nicht auf einem
        körperlichen Datenträger gespeicherten digitalen Inhalten betrifft,
        <br />
        <br />
        b. wir Ihnen eine Bestätigung des Vertrages übermitteln, und
        <br />
        <br />
        c. Sie ausdrücklich zugestimmt haben, dass wir mit der Ausführung des
        Vertrags noch vor Ablauf der sonst bestehenden 14-tägigen
        Rücktrittsfrist beginnen, und zur Kenntnis nehmen, dass Sie mit dieser
        Zustimmung Ihr Rücktrittsrecht verlieren.
      </ul>
    </Wrapper>
  </Layout>
)

export default SecondPage
