// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agb-js": () => import("../src/pages/agb.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-callback-error-js": () => import("../src/pages/callback/error.js" /* webpackChunkName: "component---src-pages-callback-error-js" */),
  "component---src-pages-callback-ok-js": () => import("../src/pages/callback/ok.js" /* webpackChunkName: "component---src-pages-callback-ok-js" */),
  "component---src-pages-help-js": () => import("../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-imprint-js": () => import("../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-members-games-breakoutz-js": () => import("../src/pages/members/games/breakoutz.js" /* webpackChunkName: "component---src-pages-members-games-breakoutz-js" */),
  "component---src-pages-members-games-candy-js": () => import("../src/pages/members/games/candy.js" /* webpackChunkName: "component---src-pages-members-games-candy-js" */),
  "component---src-pages-members-games-circle-wheel-js": () => import("../src/pages/members/games/CircleWheel.js" /* webpackChunkName: "component---src-pages-members-games-circle-wheel-js" */),
  "component---src-pages-members-games-crossing-js": () => import("../src/pages/members/games/crossing.js" /* webpackChunkName: "component---src-pages-members-games-crossing-js" */),
  "component---src-pages-members-games-gummy-js": () => import("../src/pages/members/games/gummy.js" /* webpackChunkName: "component---src-pages-members-games-gummy-js" */),
  "component---src-pages-members-games-puzzle-js": () => import("../src/pages/members/games/puzzle.js" /* webpackChunkName: "component---src-pages-members-games-puzzle-js" */),
  "component---src-pages-members-index-js": () => import("../src/pages/members/index.js" /* webpackChunkName: "component---src-pages-members-index-js" */),
  "component---src-pages-payment-js": () => import("../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-privacy-js": () => import("../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-widerruf-js": () => import("../src/pages/widerruf.js" /* webpackChunkName: "component---src-pages-widerruf-js" */)
}

