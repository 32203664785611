import React from "react"
import { Link } from "gatsby"
import { ParallaxBanner } from "react-scroll-parallax"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Wrapper from "../components/wrapper"
import GridItem from "../components/griditem"
import GameItem from "../components/gameitem"

const SecondPage = () => (
  <Layout>
    <SEO title="AGB" />
    <ParallaxBanner
      className="your-class"
      layers={[
        {
          image:
            "https://images.unsplash.com/photo-1564049489314-60d154ff107d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2561&q=80",
          amount: 0.2,
        },
      ]}
      style={{
        height: "200px",
      }}
    ></ParallaxBanner>
    <Wrapper padding>
      <h2 style={{ textAlign: "center", fontWeight: 500, width: "100%" }}>
        AGB
      </h2>
      <ul class="portfolioPrev">
        <br />
        § 1 Geltungsbereich
        <br />
        Die vorliegenden Geschäftsbedingungen enthalten die zwischen Ihnen -
        nachfolgend "Kunde" genannt - und uns, der Brain Offices GmbH,
        Mühlenstr. 8 a, D-14167 Berlin, Deutschland - nachfolgend "Anbieter"
        genannt - ausschliesslich geltenden Bedingungen, soweit diese nicht
        durch schriftliche Vereinbarungen zwischen den Parteien abgeändert
        werden. Abweichende oder entgegenstehende Bedingungen werden von uns
        nicht anerkannt, sofern wir diesen nicht ausdrücklich zugestimmt haben.
        <br />
        <br />
        § 2 Vertragsgegenstand, Vertragsschluss
        <br />
        2.1 Gegenstand dieses Vertrages ist die Bereitstellung von Produkten,
        insbesondere Spielen speziell für das Smartphone und andere mobile
        Endgeräte. Der Inhalt der jeweiligen Leistungen ergibt sich aus der
        Produktbeschreibung des Anbieters. Die bereitgestellten Produkte
        (Spiele) können von dem Kunden nur genutzt werden, sofern dieser im
        Besitz eines Smartphone ist, welches die technischen Anforderungen des
        gewünschten Produkts erfüllt und der Kunde bei einem Mobilfunkanbieter
        und/oder Service Provider ist bei dem die Produkte des Anbieters
        verfügbar sind.
        <br />
        2.2 Nutzungsberechtigt sind natürliche Personen, die das erforderliche
        Mindestalter von sechzehn [16] Jahren vollendet haben.
        <br />
        2.3 Die Präsentation der Produkte stellt ein bindendes Angebot
        unsererseits dar. Die Annahme des jeweiligen Angebotes durch den Kunden
        erfolgt sodann durch das Anklicken der gut lesbaren Schaltfläche
        "zahlungspflichtig bestellen". Unmittelbar nach Vertragsschluss erhält
        der Kunde zudem eine automatische Systemnachricht von seinem
        Mobilfunkanbieter.
        <br />
        <br />
        $ 3 Leistungen des Anbieters
        <br />
        Der Anbieter behält sich vor Art und Umfang seiner Produkte, die Höhe
        des von dem Kunden zu zahlenden Entgelts jederzeit für künftige
        Abrechnungszeiträume ganz oder teilweise zu ändern. Hinsichtlich der
        beabsichtigten Änderungen wird der Anbieter dem Kunden vor Inkrafttreten
        der Änderung eine entsprechende Mitteilung zukommen lassen. Der kann der
        beabsichtigten Änderung innerhalb von zwei [2] Wochen ab Erhalt der
        Mitteilung widersprechen. Widerspricht der Kunde, so wird der
        Abonnement-Vertrag zu den bisher geltenden Bedingungen fortgesetzt.
        Sofern der Kunde den Änderungen nicht innerhalb der vorbenannten Frist
        widerspricht wird der Vertrag zum Beginn des auf den Ablauf der Frist
        folgenden Abrechnungszeitraums zu den geänderten Bedingungen
        fortgesetzt. Bei den Produkten handelt es sich um Wap-Abo-Dienste, sowie
        Zeitzugänge per Wap-Einmalzahlungen.
        <br />
        <br />
        § 4 Laufzeit
        <br />
        4.1 Sofern bei Vertragsabschluss nicht ausdrücklich etwas anderes
        vereinbart wurde, richtet sich die Laufzeit des Vertrages nach dem
        Abrechnungszeitraum über den der Kunde im Rahmen des Vertragsschlusses
        informiert worden ist. Wird der Vertrag eines Abo-Dienstes nicht vor
        Ablauf eines Abrechnungszeitraums wirksam gekündigt, verlängert sich der
        Vertrag jeweils um einen weiteren Abrechnungszeitraum [7 Tage]. Ein
        Zeitzugang endet automatisch nach Ablauf der angegeben Zugangszeit [1
        Tag bzw. 1 Monat]
        <br />
        4.2 Beide Vertragsparteien sind berechtigt den Vertrag jederzeit ohne
        Angabe von Gründen schriftlich oder per E-Mail mit Wirkung zum Ende des
        laufenden Abrechnungszeitraums zu kündigen. Die Kündigungserklärung ist
        schriftlich unter Angabe der Mobilfunknummer an Brain Offices GmbH,
        Mühlenstr. 8 a, D-14167 Berlin, Deutschland oder per E-Mail an
        info@care-tech.info zu richten.
        <br />
        4.3 Darüber hinaus haben Sie die Möglichkeit die zwischen Ihnen und dem
        Anbieter bestehenden Verträge über die Wap-Seite des Anbieters unter dem
        Menüpunkt "Konto verwalten" einzusehen und zu kündigen.
        <br />
        <br />
        § 5 Entgelt
        <br />
        5.1 Die angebotenen Produkte des Anbieters sind kostenpflichtig, sofern
        diese nicht explizit von dem Anbieter als kostenlos bezeichnet werden.
        Der Anbieter stellt dem Kunden für die Nutzung des Produktes den in dem
        jeweiligen Abrechnungszeitraum gültigen Preis in Rechnung. Die jeweils
        aktuellen Preise sind im Internet auf den Wap-Seiten des Anbieters
        einsehbar. Der Anbieter ist für zukünftige Abrechnungszeiträume
        berechtigt, die Preise jederzeit zu ändern.
        <br />
        5.2 Die Zahlung erfolgt per Mobiltelefon. Bei Vodafone wird die Zahlung
        mittels "Vodafone Mobiles Bezahlen" durchgeführt. Sofern der Kunde mit
        seinem Mobilfunk-Netzbetreiber oder Service-Provider einen
        Laufzeitvertrag geschlossen hat, werden die Entgelte aus der
        Inanspruchnahme der Produkte mit der Mobilfunkrechnung des Kunden
        abgerechnet. Bei Kunden mit Guthabenkarten, sog. "Pre-Paid", wird das
        jeweilige Entgelt unmittelbar von dem Pre-Paid-Guthaben des Kunden
        abgebucht. Gleiches gilt bei von Dritten, bspw.
        Mobilfunk-Netzbetreibern, angebotenen Bezahlverfahren.
        <br />
        <br />
        5.3 Das Entgelt wird bei Vertragsschluss und dann für jeden weiteren
        Abrechnungszeitraum mit dessen Beginn fällig.
        <br />
        <br />
        § 6 Gebühren des Mobilfunkanbieters
        <br />
        Der Anbieter weist ausdrücklich darauf hin, dass dem Kunden im
        Zusammenhang mit der Inanspruchnahme der Wap-Abo-Dienste bzw.
        Wap-Zeitzugängen von Seiten des jeweiligen Mobilfunkanbieters oder
        etwaigen Serviceprovidern zusätzlich Kosten für die Internet- oder
        Wap-Verbindung entstehen können. Diese Kosten sind von dem Kunden zu
        tragen und gesondert gegenüber dem Mobilfunkanbieter oder
        Serviceprovider zu vergüten. Die Preise hierfür werden von dem
        jeweiligen Mobilfunkanbieter oder Serviceprovider festgelegt.
        <br />
        <br />
        § 7 Urheberrecht
        <br />
        Sämtliche angebotenen Produkte des Anbieters sowie deren Inhalte sind
        durch Urheber-, Marken- und andere gewerbliche Schutzrechte rechtlich
        geschützt. Der Kunde ist lediglich berechtigt die Produkte des Anbieters
        für private, nicht-gewerbliche Zwecke zu verwenden.
        <br />
        <br />
        § 8 Haftungsbeschränkung
        <br />
        8.1 Wir haften für Vorsatz und grobe Fahrlässigkeit. Ferner haften wir
        für die fahrlässige Verletzung von Pflichten, deren Erfüllung die
        ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht,
        deren Verletzung die Erreichung des Vertragszwecks gefährdet und auf
        deren Einhaltung Sie als Kunde regelmäßig vertrauen. Im letztgenannten
        Fall haften wir jedoch nur für den vorhersehbaren, vertragstypischen
        Schaden. Wir haften nicht für die leicht fahrlässge Verletzung anderer
        als der in den vorstehenden Sätzen genannten Pflichten.
        <br />
        Die vorstehenden Haftungsausschlüsse gelten nicht bei Verletzung von
        Leben, Körper und Gesundheit.
        <br />
        8.2 Die Datenkommunikation über das Datennetz kann nach derzeitigem
        Stand der Technik nicht fehlerfrei und/oder jederzeit verfügbar
        gewährleistet werden. Der Anbieter haftet insoweit weder für die
        ständige und ununterbrochene Verfügbarkeit der angebotene Produkte.
        <br />
        <br />
        § 9 Gewährleistung
        <br />
        Der Anbieter gibt im Hinblick auf seine Produkte keine Zusicherungen
        oder Garantien ab. Die Rechte des Kunden wegen Sach- und Rechtsmängeln
        ergeben sich aus den gesetzlichen Bestimmungen. Der Anbieter übernimmt
        keine Gewähr für die Vollständigkeit und Aktualität der angebotenen
        Inhalte.
        <br />
        <br />
        § 10 Datenschutz
        <br />
        Der Anbieter verwendet sämtliche personenbezogenen Daten des Kunden, die
        im Rahmen des Vertragsschlusses erhoben werden, ausschließlich gemäß den
        geltenden Bestimmungen des deutschen Datenschutzrechts.
        <br />
        Ihre personenbezogenen Daten, soweit diese für die Begründung,
        inhaltliche Ausgestaltung oder Änderung des Vertragsverhältnisses
        erforderlich sind, werden ausschließlich zur Abwicklung der zwischen uns
        geschlossenen Verträge verwendet. Sofern für die Erbringung der von dem
        Anbieter geschuldeten vertraglichen Leistungen oder zu
        Abrechnungszwecken die Dienste eines Dritten erforderlich sind, ist der
        Anbieter im Rahmen der Bestimmungen des deutschen Datenschutzrechts
        berechtigt und verpflichtet, Daten im zur Vertragserfüllung und
        Abrechnung erforderlichen Umfang an den Erbringer der betreffenden
        Dienstleistung zu übertragen. Zu diesen an Dritte zu übertragende Daten
        zählen insbesondere die Mobilfunknummer (MSISDN) des Kunden, der
        Zeitpunkt des Vertragsabschlusses sowie das für das jeweilige Produkt zu
        zahlende Entgelt.
        <br />
        Eine darüberhinausgehende Nutzung Ihrer Bestandsdaten für Zwecke der
        Werbung, der Marktforschung oder zur bedarfsgerechten Gestaltung unserer
        Angebote bedarf Ihrer ausdrücklichen Einwilligung. Die
        Einwilligungserklärung erfolgt völlig freiwillig und kann von Ihnen
        jederzeit widerrufen werden.
        <br />
        <br />
        § 11 Widerrufsbelehrung
        <br />
        Widerrufsrecht
        <br />
        Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen
        diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage
        ab dem Tag des Vertragsabschlusses.
        <br />
        <br />
        Um Ihr Widerrufsrecht auszuüben, müssen Sie uns, Brain Offices GmbH,
        Mühlenstr. 8 a, D-14167 Berlin, Deutschland, info@care-tech.info ,
        mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter
        Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen,
        informieren. Bitte verwenden Sie dafür das folgende
        Muster-Widerrufsformular. Zur Wahrung der Widerrufsfrist reicht es aus,
        dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf
        der Widerrufsfrist absenden.
        <br />
        <br />
        Musterwiderrufsformular:
        <br />
        <br />
        (wenn sie den Vertrag widerrufen wollen, dann füllen sie bitte dieses
        Formular aus und senden Sie es zurück)
        <br />
        <br />
        -An:
        <br />
        Brain Offices GmbH <br />
        Mühlenstr. 8 a <br />
        D-14167 Berlin
        <br />
        Deutschland
        <br />
        info@care-tech.info
        <br />
        <br />
        - Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen
        Vertrag über den Kauf der folgenden Waren (*)/die Erbringung der
        folgenden Dienstleistung (*)
        <br />
        <br />
        <br />
        <br />
        <br />
        - Bestellt am (*)/erhalten am (*)
        <br />
        <br />
        <br />
        <br />
        - Name des/der Verbraucher(s)
        <br />
        <br />
        <br />
        - Anschrift des/der Verbraucher(s)
        <br />
        <br />
        <br />
        <br />
        <br />
        - Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
        <br />
        <br />
        <br />
        - Datum
        <br />
        <br />
        (*) Unzutreffendes streichen.
        <br />
        <br />
        Folgen des Widerrufs
        <br />
        Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die
        wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit
        Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine
        andere Art der Lieferung als die von uns angebotene, günstigste
        Standardlieferung gewählt haben), unverzüglich und spätestens binnen
        vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über
        Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese
        Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der
        ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen
        wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen
        wegen dieser Rückzahlung Entgelte berechnet.
        <br />
        <br />
        § 12 Änderungen der AGB
        <br />
        Der Anbieter ist berechtigt, die AGB für künftige Abonnement-Verträge zu
        ändern, soweit die Änderungen dem Kunden zumutbar sind. Änderungen der
        AGB werden dem Kunden frühzeitig schriftlich, per E-Mail oder in
        sonstiger geeigneter Weise bekannt gegeben. Die Änderungen gelten als
        genehmigt und mit Inkrafttreten für ein bestehendes Vertragsverhältnis
        als binden, wenn der Kunde weder schriftlich noch per E-Mail Widerspruch
        erhebt. Auf diese Folge wird der Kunde bei der Bekanntgabe von
        Änderungen durch den Anbieter besonders hingewiesen. Der Widerspruch des
        Kunden muss innerhalb eines Monats nach Bekanntgabe der Änderungen an
        den Anbieter abgesendet werden.
        <br />
        <br />
        § 13 Schlussbestimmungen
        <br />
        13.1 Änderungen oder Ergänzungen dieser Geschäftsbedingungen bedürfen
        der Schriftform. Dies gilt auch für die Aufhebung dieses
        Schriftformerfordernisses.
        <br />
        13.2 Es gilt das Recht der Bundesrepublik Deutschland.
        <br />
        13.3 Ausschließlicher Gerichtsstand für alle Streitigkeiten aus oder im
        Zusammenhang mit diesem Vertrag ist Hamburg.
        <br />
        13.4 Sollten einzelne Bestimmungen dieses Vertrages unwirksam sein oder
        den gesetzlichen Regelungen widersprechen, so wird hierdurch der Vertrag
        im übrigen nicht berührt. Die unwirksame Bestimmung wird von den
        Vertragsparteien einvernehmlich durch eine rechtswirksame Bestimmung
        ersetzt, welche dem wirtschaftlichen Sinn und Zweck der unwirksamen
        Bestimmung am nächsten kommt. Die vorstehende Regelung gilt entsprechend
        bei Regelungslücken.
        <br />
        <br />
        Stand: 23.2.2018
        <br />
      </ul>
    </Wrapper>
  </Layout>
)

export default SecondPage
