import React, { useEffect } from "react"
import Loader from "react-loader-spinner"
import { useQueryParam, StringParam } from "use-query-params"
import { navigate } from "gatsby"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Wrapper from "../../components/wrapper"

const OKCallback = () => {
  const [txId, settxId] = useQueryParam("txId", StringParam)

  useEffect(() => {
    console.log(txId)
    if (txId) {
      fetch(`/.netlify/functions/bill`, {
        body: JSON.stringify({ id: txId }),
        method: "POST",
      })
        .then(response => {
          const res = response.json()
          if (res.billed) {
            navigate("/members")
          }
        })
        .catch(err => {
          console.log(err)
          navigate("/callback/error")
        })
    }
  }, [])

  return (
    <Layout>
      <SEO title="Success" />
      <Wrapper>
        <div
          style={{
            width: "100%",
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Loader type="Circles" color="#eb217c" height={100} width={100} />
          <h3 style={{ marginTop: 30 }}>Zugang wird freigeschaltet...</h3>
          <p>Bitte haben Sie etwas gedult</p>
        </div>
      </Wrapper>
    </Layout>
  )
}

export default OKCallback
