/* eslint-disable import/first */
import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import { useQueryParam, StringParam } from "use-query-params"
import Loader from "react-loader-spinner"
import PhoneInput from "react-phone-number-input"
import "react-phone-number-input/style.css"

import SEO from "../components/seo"
import Logo from "../images/logo3_high_bg.png"
import ZahlEinfach from "../images/Zahl_einfach_Logo.png"
import Telekom from "../images/logo_telekom.png"
import Mobilcom from "../images/logo_mobilcom.png"
import Vodafone from "../images/logo_vodafone.png"
import O2 from "../images/logo_o2.png"

const PaymentPage = () => {
  const [phone, setPhone] = useQueryParam("phone", StringParam)
  console.log({ phone })

  const [code, setCode] = useState(false)
  const [codeVal, setCodeVal] = useState("")
  const [loading, setLoading] = React.useState(false)
  const [buyDisabled, setBuyDisabled] = useState(true)
  const [txId, setTxId] = useState(null)

  const startAuth = phone => {
    setLoading(true)
    return fetch(`/.netlify/functions/start`, {
      body: JSON.stringify({ phone }),
      method: "POST",
    })
      .then(response => {
        setLoading(false)
        return response.json()
      })
      .catch(err => {
        console.log(err)
        alert("Ein fehler ist aufgetreten")
        setLoading(false)
        return null
      })
  }

  const verifyPin = (id, pin) => {
    setLoading(true)
    return fetch(`/.netlify/functions/pin`, {
      body: JSON.stringify({ id, pin }),
      method: "POST",
    })
      .then(response => {
        setLoading(false)
        return response.json()
      })
      .catch(err => {
        console.log(err)
        alert("Ein fehler ist aufgetreten")
        setLoading(false)
        return null
      })
  }

  const bill = id => {
    setLoading(true)
    return fetch(`/.netlify/functions/bill`, {
      body: JSON.stringify({ id }),
      method: "POST",
    })
      .then(response => {
        setLoading(false)
        return response.json()
      })
      .catch(err => {
        console.log(err)
        alert("Ein fehler ist aufgetreten")
        setLoading(false)
        return null
      })
  }

  return (
    <div className="mwdkbody">
      <SEO title="Payment" />
      <div className="mainWrapper">
        <div className="main">
          <div className="cancelWrapper">
            <div className="cancelButton" onClick={() => navigate("/")}>
              <span>X</span>
            </div>
          </div>
          <div className="header">
            <div className="topInfo">
              <h2 className="mwdkh2 prodName">123 Spielewelt</h2>
            </div>
            <div className="productPic">
              <img src={Logo} alt="Produktbild" />
            </div>
            <div className="clear"></div>
          </div>
          <div className="content">
            <div className="dabContainer">
              <p className="dabName">Brain Offices GmbH</p>
              <p className="dabProdName">123spielewelt.de</p>
            </div>
            <div className="details">
              <div className="description">
                123spielewelt.de bietet die Onlinespiele im günstigen
                Abonnement! Bezahle 0.99 Euro pro Woche und erhalte
                uneingeschränkten Zugriff auf alle unsere Spiele.
              </div>
            </div>
          </div>
          <div style={{ clear: "both" }} />
          <div className="legal">
            <form name="web_auth_phone" method="post">
              <div className="msisdnInput">
                <div className="form-group">
                  {code === false ? (
                    <div>
                      <label htmlFor="web_auth_phone_phoneNumber">
                        Bitte geben Sie Ihre Handynummer ein:
                      </label>
                      <PhoneInput
                        placeholder="Handynummer eingeben"
                        defaultCountry="DE"
                        value={phone}
                        onChange={value => setPhone(value)}
                        style={{
                          marginTop: 30,
                          background: "#FFF",
                          borderRadius: 25,
                          padding: "5px 15px",
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <label htmlFor="web_auth_phone_phoneNumber">
                        Bitte geben Sie die erhaltene TAN ein:
                      </label>
                      <input
                        type={"text"}
                        value={codeVal}
                        placeholder="TAN eingeben"
                        onChange={e => setCodeVal(e.target.value)}
                        className={"CodeInput mwdkInput"}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div
                id="googleCheckboxDiv"
                style={{ display: "none" }}
                className="msisdnInput"
              >
                <input type="checkbox" id="googleCheckbox" checked="checked" />
                Der Preis für das Abo beträgt 0.99 Euro (inkl. MwSt.) pro 7
                Tage.
              </div>
              <div className="aboContainer ">
                <b>Preis für das Abo: 0.99 Euro </b> (inkl. MwSt.) pro 7 Tage.
                Abgerechnet wird über die Mobilfunkrufnummer. Das Abo ist mit
                Tagesfrist zum Ende des jeweiligen Abrechnungszeitraums auch
                über den Kundenservice 08005895405 kündbar.
              </div>
              <div className="aboContainer notRendered">
                <b>Preis für den Einzelkauf: 0.99 Euro</b> inklusive MwSt.
                Abgerechnet wird über die Mobilfunkrufnummer.{" "}
              </div>
              {code ? (
                <div>
                  <div className="aboContainer breakline abgCheckboxContainer">
                    {" "}
                    Es gelten unsere{" "}
                    <Link to="/agb">allgemeinen Geschäftsbedingungen</Link>. Die{" "}
                    <Link to="/agb">Widerrufsbelehrung</Link> finden Sie{" "}
                    <Link to="/agb">hier</Link>
                  </div>
                  <div className="aboContainer breakline abgCheckboxContainer">
                    <input
                      id="checkboxAGB"
                      type="checkbox"
                      onChange={() => setBuyDisabled(s => !s)}
                    />
                    &nbsp;&nbsp;Es gelten unsere{" "}
                    <Link to="/agb">allgemeinen Geschäftsbedingungen</Link>. Ich
                    stimme der Ausführung des Vertrages vor Ablauf der
                    Widerrufsfrist (<Link to="/agb">Widerrufsbelehrung</Link>)
                    ausdrücklich zu. Ich habe zur Kenntnis genommen, dass mein
                    Widerrufsrecht mit Beginn der Ausführung des Vertrags
                    erlischt.
                  </div>
                </div>
              ) : null}
              <div className="buyButton">
                <div className="formContainer">
                  <div className="btnContainer">
                    <div className="form-group">
                      {!code ? (
                        <button
                          disabled={loading}
                          type="submit"
                          id="web_auth_phone_kaufenBtn"
                          name="web_auth_phone[kaufenBtn]"
                          className="payment-button btn"
                          onClick={async e => {
                            e.preventDefault()
                            const res = await startAuth(phone)
                            if (res) {
                              // if (res.redirectUri) {
                              //   window.location.href = res.redirectUri;
                              // }
                              setTxId(res.id)
                              setCode(true)
                            }
                          }}
                        >
                          {loading ? (
                            <Loader
                              type="Circles"
                              color="#FFF"
                              height={20}
                              width={20}
                            />
                          ) : (
                            <span>Weiter</span>
                          )}
                        </button>
                      ) : (
                        <button
                          disabled={buyDisabled || loading}
                          type="submit"
                          id="web_auth_pin_buyNow"
                          name="web_auth_pin[buyNow]"
                          className="payment-button btn"
                          onClick={async e => {
                            e.preventDefault()
                            const res = await verifyPin(txId, codeVal)
                            if (res.verified) {
                              const billRes = await bill(txId)
                              if (billRes.billed) {
                                navigate("/members")
                              } else {
                                navigate("/callback/error")
                              }
                            } else {
                              navigate("/callback/error")
                            }
                          }}
                        >
                          {loading ? (
                            <Loader
                              type="Circles"
                              color="#FFF"
                              height={20}
                              width={20}
                            />
                          ) : (
                            <span>Zahlungspflichtig bestellen</span>
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="btnContainer">
                    <div>
                      <div className="form-group">
                        <button
                          disabled={loading}
                          type="submit"
                          id="web_auth_phone_backBtn"
                          name="web_auth_phone[backBtn]"
                          className="payment-button btn"
                          onClick={e => {
                            e.preventDefault()
                            navigate("/")
                          }}
                        >
                          Zurück
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <input
                type="hidden"
                id="web_auth_phone__token"
                name="web_auth_phone[_token]"
                value="aD34O9t5jv4S5Sf_NYbAkwmB9zt2xTFdJEaeLiFWdGA"
              />
            </form>
          </div>
          <div style={{ clear: "both" }} />
          <div className="footerLinks">
            <div className="pink">
              <Link to="/help">Hilfe</Link>
            </div>
            <div className="pink">
              <Link to="/imprint">Kontakt</Link>
            </div>
            <div className="pink">
              <Link to="/widerruf">Widerrufbelehrung</Link>
            </div>
            <div className="pink">
              <Link to="/imprint">Impressum</Link>
            </div>
            <div className="pink notRendered">
              <a href="http://einzelkauf.de">Kündigung</a>
            </div>
            <div className="pink">
              <Link to="/agb">AGB</Link>
            </div>
            <div className="pink">
              <Link to="/privacy">Datenschutz</Link>
            </div>
          </div>
          <div className="logoZEPH">
            <img src={ZahlEinfach} />
          </div>
          <div className="logos">
            <img src={Telekom} />
            <img src={Vodafone} />
            <img src={O2} />
            <img src={Mobilcom} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentPage
