import React from "react"

export default ({ children, padding, style }) => (
  <div
    style={{
      margin: `0 auto`,
      maxWidth: 1100,
      width: "90%",
      padding: `${padding ? "5rem" : "0"} 0 `,
      ...style,
    }}
  >
    {children}
  </div>
)
