import React from "react"
import Plx from "react-plx"

const phoneData = [
  {
    start: "self",
    startOffset: 0,
    duration: 600,
    easing: [0.25, 0.1, 0.6, 1.5],
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "scale",
      },
    ],
  },
]

export default ({ title, color, icon }) => (
  <Plx parallaxData={phoneData}>
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 20,
      }}
    >
      <div
        style={{
          width: 150,
          height: 150,
          borderRadius: "50%",
          background: color,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {icon ? <img src={icon} height="70px" width="70px" /> : null}
      </div>
      <h3 style={{ textAlign: "center", fontWeight: 500, marginTop: 20 }}>
        {title}
      </h3>
    </div>
  </Plx>
)
