import React from "react"
import { Link } from "gatsby"
import { ParallaxBanner } from "react-scroll-parallax"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Wrapper from "../components/wrapper"

const SecondPage = () => (
  <Layout>
    <SEO title="Impressum" />
    <ParallaxBanner
      className="your-class"
      layers={[
        {
          image:
            "https://images.unsplash.com/photo-1564049489314-60d154ff107d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2561&q=80",
          amount: 0.2,
        },
      ]}
      style={{
        height: "200px",
      }}
    ></ParallaxBanner>
    <Wrapper padding>
      <h2 style={{ textAlign: "center", fontWeight: 500, width: "100%" }}>
        Datenschutzerklärung
      </h2>
      <h2>Einleitung</h2>
      <p>
        Mit der folgenden Datenschutzerklärung möchten wir Sie darüber
        aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend auch
        kurz als "Daten“ bezeichnet) wir zu welchen Zwecken und in welchem
        Umfang verarbeiten. Die Datenschutzerklärung gilt für alle von uns
        durchgeführten Verarbeitungen personenbezogener Daten, sowohl im Rahmen
        der Erbringung unserer Leistungen als auch insbesondere auf unseren
        Webseiten, in mobilen Applikationen sowie innerhalb externer
        Onlinepräsenzen, wie z.B. unserer Social-Media-Profile (nachfolgend
        zusammenfassend bezeichnet als "Onlineangebot“).
      </p>
      <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
      <p>Stand: 15. Januar 2020</p>
      <h2>Inhaltsübersicht</h2>{" "}
      <ul class="index">
        <li>
          <a class="index-link" href="#m14">
            {" "}
            Einleitung
          </a>
        </li>
        <li>
          <a class="index-link" href="#m3">
            {" "}
            Verantwortlicher
          </a>
        </li>
        <li>
          <a class="index-link" href="#mOverview">
            {" "}
            Übersicht der Verarbeitungen
          </a>
        </li>
        <li>
          <a class="index-link" href="#m13">
            {" "}
            Maßgebliche Rechtsgrundlagen
          </a>
        </li>
        <li>
          <a class="index-link" href="#m27">
            {" "}
            Sicherheitsmaßnahmen
          </a>
        </li>
        <li>
          <a class="index-link" href="#m25">
            {" "}
            Übermittlung und Offenbarung von personenbezogenen Daten
          </a>
        </li>
        <li>
          <a class="index-link" href="#m24">
            {" "}
            Datenverarbeitung in Drittländern
          </a>
        </li>
        <li>
          <a class="index-link" href="#m134">
            {" "}
            Einsatz von Cookies
          </a>
        </li>
        <li>
          <a class="index-link" href="#m317">
            {" "}
            Kommerzielle und geschäftliche Leistungen
          </a>
        </li>
        <li>
          <a class="index-link" href="#m182">
            {" "}
            Kontaktaufnahme
          </a>
        </li>
        <li>
          <a class="index-link" href="#m225">
            {" "}
            Bereitstellung des Onlineangebotes und Webhosting
          </a>
        </li>
        <li>
          <a class="index-link" href="#m12">
            {" "}
            Löschung von Daten
          </a>
        </li>
        <li>
          <a class="index-link" href="#m15">
            {" "}
            Änderung und Aktualisierung der Datenschutzerklärung
          </a>
        </li>
        <li>
          <a class="index-link" href="#m10">
            {" "}
            Rechte der betroffenen Personen
          </a>
        </li>
        <li>
          <a class="index-link" href="#m42">
            {" "}
            Begriffsdefinitionen
          </a>
        </li>
      </ul>
      <h2 id="m3">Verantwortlicher</h2>{" "}
      <p>
        Brain Offices GmbH <br />
        Mühlenstr. 8 a <br />
        D-14167 Berlin <br />
        Deutschland
        <br />
        <br />
      </p>
      <p>
        <strong>E-Mail-Adresse</strong>:{" "}
        <a href="mailto:hilfe@123spielewelt.de">hilfe@123spielewelt.de</a>
      </p>
      <p>
        <strong>Impressum</strong>:{" "}
        <a href="https://123spielewelt.de/imprint" target="_blank">
          http://123spielewelt.de/imprint
        </a>
      </p>
      <ul class="m-elements"></ul>
      <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
      <p>
        Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und
        die Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen
        Personen.
      </p>
      <h3>Arten der verarbeiteten Daten</h3>
      <ul>
        <li>
          <p>Bestandsdaten (z.B. Namen, Adressen).</p>
        </li>
        <li>
          <p>Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos).</p>
        </li>
        <li>
          <p>Kontaktdaten (z.B. E-Mail, Telefonnummern).</p>
        </li>
        <li>
          <p>
            Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
          </p>
        </li>
        <li>
          <p>
            Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
            Zugriffszeiten).
          </p>
        </li>
        <li>
          <p>
            Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie).
          </p>
        </li>
        <li>
          <p>
            Zahlungsdaten (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie).
          </p>
        </li>
      </ul>
      <h3>Kategorien betroffener Personen</h3>
      <ul>
        <li>
          <p>Geschäfts- und Vertragspartner.</p>
        </li>
        <li>
          <p>Interessenten.</p>
        </li>
        <li>
          <p>Kommunikationspartner.</p>
        </li>
        <li>
          <p>Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</p>
        </li>
      </ul>
      <h3>Zwecke der Verarbeitung</h3>
      <ul>
        <li>
          <p>Büro- und Organisationsverfahren.</p>
        </li>
        <li>
          <p>Kontaktanfragen und Kommunikation.</p>
        </li>
        <li>
          <p>Vertragliche Leistungen und Service.</p>
        </li>
        <li>
          <p>Verwaltung und Beantwortung von Anfragen.</p>
        </li>
      </ul>
      <h2></h2>
      <h3 id="m13">Maßgebliche Rechtsgrundlagen</h3>
      <p>
        Im Folgenden teilen wir die Rechtsgrundlagen der
        Datenschutzgrundverordnung (DSGVO), auf deren Basis wir die
        personenbezogenen Daten verarbeiten, mit. Bitte beachten Sie, dass
        zusätzlich zu den Regelungen der DSGVO die nationalen
        Datenschutzvorgaben in Ihrem bzw. unserem Wohn- und Sitzland gelten
        können.
      </p>
      <ul>
        <li>
          <p>
            <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO)</strong> -
            Die betroffene Person hat ihre Einwilligung in die Verarbeitung der
            sie betreffenden personenbezogenen Daten für einen spezifischen
            Zweck oder mehrere bestimmte Zwecke gegeben.
          </p>
        </li>
        <li>
          <p>
            <strong>
              Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
              lit. b. DSGVO)
            </strong>{" "}
            - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
            Vertragspartei die betroffene Person ist, oder zur Durchführung
            vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der
            betroffenen Person erfolgen.
          </p>
        </li>
        <li>
          <p>
            <strong>
              Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO)
            </strong>{" "}
            - Die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung
            erforderlich, der der Verantwortliche unterliegt.
          </p>
        </li>
        <li>
          <p>
            <strong>
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)
            </strong>{" "}
            - Die Verarbeitung ist zur Wahrung der berechtigten Interessen des
            Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
            Interessen oder Grundrechte und Grundfreiheiten der betroffenen
            Person, die den Schutz personenbezogener Daten erfordern,
            überwiegen.
          </p>
        </li>
      </ul>
      <p>
        <strong>Nationale Datenschutzregelungen in Deutschland</strong>:
        Zusätzlich zu den Datenschutzregelungen der Datenschutz-Grundverordnung
        gelten nationale Regelungen zum Datenschutz in Deutschland. Hierzu
        gehört insbesondere das Gesetz zum Schutz vor Missbrauch
        personenbezogener Daten bei der Datenverarbeitung
        (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere
        Spezialregelungen zum Recht auf Auskunft, zum Recht auf Löschung, zum
        Widerspruchsrecht, zur Verarbeitung besonderer Kategorien
        personenbezogener Daten, zur Verarbeitung für andere Zwecke und zur
        Übermittlung sowie automatisierten Entscheidungsfindung im Einzelfall
        einschließlich Profiling. Des Weiteren regelt es die Datenverarbeitung
        für Zwecke des Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im
        Hinblick auf die Begründung, Durchführung oder Beendigung von
        Beschäftigungsverhältnissen sowie die Einwilligung von Beschäftigten.
        Ferner können Landesdatenschutzgesetze der einzelnen Bundesländer zur
        Anwendung gelangen.
      </p>
      <ul class="m-elements"></ul> <h2 id="m27">Sicherheitsmaßnahmen</h2>
      <p>
        Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
        Berücksichtigung des Stands der Technik, der Implementierungskosten und
        der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie
        der unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der
        Bedrohung der Rechte und Freiheiten natürlicher Personen geeignete
        technische und organisatorische Maßnahmen, um ein dem Risiko
        angemessenes Schutzniveau zu gewährleisten.
      </p>
      <p>
        Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit,
        Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen
        und elektronischen Zugangs zu den Daten als auch des sie betreffenden
        Zugriffs, der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit
        und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die
        eine Wahrnehmung von Betroffenenrechten, die Löschung von Daten und
        Reaktionen auf die Gefährdung der Daten gewährleisten. Ferner
        berücksichtigen wir den Schutz personenbezogener Daten bereits bei der
        Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren
        entsprechend dem Prinzip des Datenschutzes, durch Technikgestaltung und
        durch datenschutzfreundliche Voreinstellungen.
      </p>
      <p>
        <strong>SSL-Verschlüsselung (https)</strong>: Um Ihre via unser
        Online-Angebot übermittelten Daten zu schützen, nutzen wir eine
        SSL-Verschlüsselung. Sie erkennen derart verschlüsselte Verbindungen an
        dem Präfix https:// in der Adresszeile Ihres Browsers.
      </p>
      <ul class="m-elements"></ul>
      <h2 id="m25">Übermittlung und Offenbarung von personenbezogenen Daten</h2>
      <p>
        Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor,
        dass die Daten an andere Stellen, Unternehmen, rechtlich selbstständige
        Organisationseinheiten oder Personen übermittelt oder sie ihnen
        gegenüber offengelegt werden. Zu den Empfängern dieser Daten können z.B.
        Zahlungsinstitute im Rahmen von Zahlungsvorgängen, mit IT-Aufgaben
        beauftragte Dienstleister oder Anbieter von Diensten und Inhalten, die
        in eine Webseite eingebunden werden, gehören. In solchen Fall beachten
        wir die gesetzlichen Vorgaben und schließen insbesondere entsprechende
        Verträge bzw. Vereinbarungen, die dem Schutz Ihrer Daten dienen, mit den
        Empfängern Ihrer Daten ab.
      </p>
      <p>
        <strong>Datenübermittlung innerhalb der Organisation</strong>: Wir
        können personenbezogene Daten an andere Stellen innerhalb unserer
        Organisation übermitteln oder ihnen den Zugriff auf diese Daten
        gewähren. Sofern diese Weitergabe zu administrativen Zwecken erfolgt,
        beruht die Weitergabe der Daten auf unseren berechtigten
        unternehmerischen und betriebswirtschaftlichen Interessen oder erfolgt,
        sofern sie Erfüllung unserer vertragsbezogenen Verpflichtungen
        erforderlich ist oder wenn eine Einwilligung der Betroffenen oder eine
        gesetzliche Erlaubnis vorliegt.
      </p>
      <ul class="m-elements"></ul>
      <h2 id="m24">Datenverarbeitung in Drittländern</h2>
      <p>
        Sofern wir Daten in einem Drittland (d.h., außerhalb der Europäischen
        Union (EU), des Europäischen Wirtschaftsraums (EWR)) verarbeiten oder
        die Verarbeitung im Rahmen der Inanspruchnahme von Diensten Dritter oder
        der Offenlegung bzw. Übermittlung von Daten an andere Personen, Stellen
        oder Unternehmen stattfindet, erfolgt dies nur im Einklang mit den
        gesetzlichen Vorgaben.{" "}
      </p>
      <p>
        Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder
        gesetzlich erforderlicher Übermittlung verarbeiten oder lassen wir die
        Daten nur in Drittländern mit einem anerkannten Datenschutzniveau, zu
        denen die unter dem "Privacy-Shield" zertifizierten US-Verarbeiter
        gehören, oder auf Grundlage besonderer Garantien, wie z.B. vertraglicher
        Verpflichtung durch sogenannte Standardschutzklauseln der EU-Kommission,
        des Vorliegens von Zertifizierungen oder verbindlicher interner
        Datenschutzvorschriften, verarbeiten (Art. 44 bis 49 DSGVO,
        Informationsseite der EU-Kommission:{" "}
        <a
          href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de"
          target="_blank"
        >
          https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de
        </a>{" "}
        ).
      </p>
      <ul class="m-elements"></ul>
      <h2 id="m134">Einsatz von Cookies</h2>
      <p>
        Cookies sind Textdateien, die Daten von besuchten Websites oder Domains
        enthalten und von einem Browser auf dem Computer des Benutzers
        gespeichert werden. Ein Cookie dient in erster Linie dazu, die
        Informationen über einen Benutzer während oder nach seinem Besuch
        innerhalb eines Onlineangebotes zu speichern. Zu den gespeicherten
        Angaben können z.B. die Spracheinstellungen auf einer Webseite, der
        Loginstatus, ein Warenkorb oder die Stelle, an der ein Video geschaut
        wurde, gehören. Zu dem Begriff der Cookies zählen wir ferner andere
        Technologien, die die gleichen Funktionen wie Cookies erfüllen (z.B.,
        wenn Angaben der Nutzer anhand pseudonymer Onlinekennzeichnungen
        gespeichert werden, auch als "Nutzer-IDs" bezeichnet)
      </p>
      <p>
        <strong>
          Die folgenden Cookie-Typen und Funktionen werden unterschieden:
        </strong>
      </p>
      <ul>
        <li>
          <strong>
            Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):
          </strong>
          &nbsp;Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer
          ein Online-Angebot verlassen und seinen Browser geschlossen hat.
        </li>
        <li>
          <strong>Permanente Cookies:</strong>&nbsp;Permanente Cookies bleiben
          auch nach dem Schließen des Browsers gespeichert. So kann
          beispielsweise der Login-Status gespeichert oder bevorzugte Inhalte
          direkt angezeigt werden, wenn der Nutzer eine Website erneut besucht.
          Ebenso können die Interessen von Nutzern, die zur Reichweitenmessung
          oder zu Marketingzwecken verwendet werden, in einem solchen Cookie
          gespeichert werden.
        </li>
        <li>
          <strong>First-Party-Cookies:</strong>&nbsp;First-Party-Cookies werden
          von uns selbst gesetzt.
        </li>
        <li>
          <strong>Third-Party-Cookies (auch: Drittanbieter-Cookies)</strong>:
          Drittanbieter-Cookies werden hauptsächlich von Werbetreibenden (sog.
          Dritten) verwendet, um Benutzerinformationen zu verarbeiten.
        </li>
        <li>
          <strong>
            Notwendige (auch: essentielle oder unbedingt erforderliche) Cookies:
          </strong>{" "}
          Cookies können zum einen für den Betrieb einer Webseite unbedingt
          erforderlich sein (z.B. um Logins oder andere Nutzereingaben zu
          speichern oder aus Gründen der Sicherheit).
        </li>
        <li>
          <strong>Statistik-, Marketing- und Personalisierungs-Cookies</strong>:
          Ferner werden Cookies im Regelfall auch im Rahmen der
          Reichweitenmessung eingesetzt sowie dann, wenn die Interessen eines
          Nutzers oder sein Verhalten (z.B. Betrachten bestimmter Inhalte,
          Nutzen von Funktionen etc.) auf einzelnen Webseiten in einem
          Nutzerprofil gespeichert werden. Solche Profile dienen dazu, den
          Nutzern z.B. Inhalte anzuzeigen, die ihren potentiellen Interessen
          entsprechen. Dieses Verfahren wird auch als "Tracking", d.h.,
          Nachverfolgung der potentiellen Interessen der Nutzer bezeichnet. .
          Soweit wir Cookies oder "Tracking"-Technologien einsetzen, informieren
          wir Sie gesondert in unserer Datenschutzerklärung oder im Rahmen der
          Einholung einer Einwilligung.
        </li>
      </ul>
      <p>
        <strong>Hinweise zu Rechtsgrundlagen: </strong> Auf welcher
        Rechtsgrundlage wir Ihre personenbezogenen Daten mit Hilfe von Cookies
        verarbeiten, hängt davon ab, ob wir Sie um eine Einwilligung bitten.
        Falls dies zutrifft und Sie in die Nutzung von Cookies einwilligen, ist
        die Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte
        Einwilligung. Andernfalls werden die mithilfe von Cookies verarbeiteten
        Daten auf Grundlage unserer berechtigten Interessen (z.B. an einem
        betriebswirtschaftlichen Betrieb unseres Onlineangebotes und dessen
        Verbesserung) verarbeitet oder, wenn der Einsatz von Cookies
        erforderlich ist, um unsere vertraglichen Verpflichtungen zu erfüllen.
      </p>
      <p>
        <strong>
          Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out):{" "}
        </strong>{" "}
        Abhängig davon, ob die Verarbeitung auf Grundlage einer Einwilligung
        oder gesetzlichen Erlaubnis erfolgt, haben Sie jederzeit die
        Möglichkeit, eine erteilte Einwilligung zu widerrufen oder der
        Verarbeitung Ihrer Daten durch Cookie-Technologien zu widersprechen
        (zusammenfassend als "Opt-Out" bezeichnet). Sie können Ihren Widerspruch
        zunächst mittels der Einstellungen Ihres Browsers erklären, z.B., indem
        Sie die Nutzung von Cookies deaktivieren (wobei hierdurch auch die
        Funktionsfähigkeit unseres Onlineangebotes eingeschränkt werden kann).
        Ein Widerspruch gegen den Einsatz von Cookies zu Zwecken des
        Onlinemarketings kann auch mittels einer Vielzahl von Diensten, vor
        allem im Fall des Trackings, über die Webseiten{" "}
        <a href="http://optout.aboutads.info" target="_blank">
          http://optout.aboutads.info
        </a>{" "}
        und{" "}
        <a href="http://www.youronlinechoices.com/" target="_blank">
          http://www.youronlinechoices.com/
        </a>{" "}
        erklärt werden. Daneben können Sie weitere Widerspruchshinweise im
        Rahmen der Angaben zu den eingesetzten Dienstleistern und Cookies
        erhalten.
      </p>
      <p>
        <strong>
          Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung
        </strong>
        : Bevor wir Daten im Rahmen der Nutzung von Cookies verarbeiten oder
        verarbeiten lassen, bitten wir die Nutzer um eine jederzeit widerrufbare
        Einwilligung. Bevor die Einwilligung nicht ausgesprochen wurde, werden
        allenfalls Cookies eingesetzt, die für den Betrieb unseres
        Onlineangebotes erforderlich sind. Deren Einsatz erfolgt auf der
        Grundlage unseres Interesses und des Interesses der Nutzer an der
        erwarteten Funktionsfähigkeit unseres Onlineangebotes.
      </p>
      <ul class="m-elements">
        <li>
          <p>
            <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B.
            besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
            Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
          </p>
        </li>
        <li>
          <p>
            <strong>Betroffene Personen:</strong> Nutzer (z.B.
            Webseitenbesucher, Nutzer von Onlinediensten).
          </p>
        </li>
        <li>
          <p>
            <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1
            lit. a DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
            DSGVO).
          </p>
        </li>
      </ul>
      <h2 id="m317">Kommerzielle und geschäftliche Leistungen</h2>
      <p>
        Wir verarbeiten Daten unserer Vertrags- und Geschäftspartner, z.B.
        Kunden und Interessenten (zusammenfassend bezeichnet als
        "Vertragspartner") im Rahmen von vertraglichen und vergleichbaren
        Rechtsverhältnissen sowie damit verbundenen Maßnahmen und im Rahmen der
        Kommunikation mit den Vertragspartnern (oder vorvertraglich), z.B., um
        Anfragen zu beantworten.
      </p>
      <p>
        Diese Daten verarbeiten wir zur Erfüllung unserer vertraglichen
        Pflichten, zur Sicherung unserer Rechte und zu Zwecken der mit diesen
        Angaben einhergehenden Verwaltungsaufgaben sowie der unternehmerischen
        Organisation. Die Daten der Vertragspartner geben wir im Rahmen des
        geltenden Rechts nur insoweit an Dritte weiter, als dies zu den
        vorgenannten Zwecken oder zur Erfüllung gesetzlicher Pflichten
        erforderlich ist oder mit Einwilligung der Vertragspartner erfolgt (z.B.
        an beteiligte Telekommunikations-, Transport- und sonstige Hilfsdienste
        sowie Subunternehmer, Banken, Steuer- und Rechtsberater,
        Zahlungsdienstleister oder Steuerbehörden). Über weitere
        Verarbeitungsformen, z.B. zu Zwecken des Marketings, werden die
        Vertragspartner im Rahmen dieser Datenschutzerklärung informiert.
      </p>
      <p>
        Welche Daten für die vorgenannten Zwecke erforderlich sind, teilen wir
        den Vertragspartnern vor oder im Rahmen der Datenerhebung, z.B. in
        Onlineformularen, durch besondere Kennzeichnung (z.B. Farben) bzw.
        Symbole (z.B. Sternchen o.ä.), oder persönlich mit.
      </p>
      <p>
        Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und
        vergleichbarer Pflichten, d.h., grundsätzlich nach Ablauf von 4 Jahren,
        es sei denn, dass die Daten in einem Kundenkonto gespeichert werden,
        z.B., solange sie aus gesetzlichen Gründen der Archivierung aufbewahrt
        werden müssen (z.B. für Steuerzwecke im Regelfall 10 Jahre). Daten, die
        uns im Rahmen eines Auftrags durch den Vertragspartner offengelegt
        wurden, löschen wir entsprechend den Vorgaben des Auftrags,
        grundsätzlich nach Ende des Auftrags.
      </p>
      <p>
        Soweit wir zur Erbringung unserer Leistungen Drittanbieter oder
        Plattformen einsetzen, gelten im Verhältnis zwischen den Nutzern und den
        Anbietern die Geschäftsbedingungen und Datenschutzhinweise der
        jeweiligen Drittanbieter oder Plattformen.
      </p>
      <ul class="m-elements">
        <li>
          <p>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
            Adressen), Zahlungsdaten (z.B. Bankverbindungen, Rechnungen,
            Zahlungshistorie), Kontaktdaten (z.B. E-Mail, Telefonnummern),
            Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie).
          </p>
        </li>
        <li>
          <p>
            <strong>Betroffene Personen:</strong> Interessenten, Geschäfts- und
            Vertragspartner.
          </p>
        </li>
        <li>
          <p>
            <strong>Zwecke der Verarbeitung:</strong> Vertragliche Leistungen
            und Service, Kontaktanfragen und Kommunikation, Büro- und
            Organisationsverfahren, Verwaltung und Beantwortung von Anfragen.
          </p>
        </li>
        <li>
          <p>
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
            vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO),
            Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO),
            Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
          </p>
        </li>
      </ul>
      <h2 id="m182">Kontaktaufnahme</h2>
      <p>
        Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail,
        Telefon oder via soziale Medien) werden die Angaben der anfragenden
        Personen verarbeitet, soweit dies zur Beantwortung der Kontaktanfragen
        und etwaiger angefragter Maßnahmen erforderlich ist.
      </p>
      <p>
        Die Beantwortung der Kontaktanfragen im Rahmen von vertraglichen oder
        vorvertraglichen Beziehungen erfolgt zur Erfüllung unserer vertraglichen
        Pflichten oder zur Beantwortung von (vor)vertraglichen Anfragen und im
        Übrigen auf Grundlage der berechtigten Interessen an der Beantwortung
        der Anfragen.
      </p>
      <ul class="m-elements">
        <li>
          <p>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
            Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten
            (z.B. Texteingaben, Fotografien, Videos).
          </p>
        </li>
        <li>
          <p>
            <strong>Betroffene Personen:</strong> Kommunikationspartner.
          </p>
        </li>
        <li>
          <p>
            <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
            Kommunikation.
          </p>
        </li>
        <li>
          <p>
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
            vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO),
            Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
          </p>
        </li>
      </ul>
      <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
      <p>
        Um unser Onlineangebot sicher und effizient bereitstellen zu können,
        nehmen wir die Leistungen von einem oder mehreren Webhosting-Anbietern
        in Anspruch, von deren Servern (bzw. von ihnen verwalteten Servern) das
        Onlineangebot abgerufen werden kann. Zu diesen Zwecken können wir
        Infrastruktur- und Plattformdienstleistungen, Rechenkapazität,
        Speicherplatz und Datenbankdienste sowie Sicherheitsleistungen und
        technische Wartungsleistungen in Anspruch nehmen.
      </p>
      <p>
        Zu den im Rahmen der Bereitstellung des Hostingangebotes verarbeiteten
        Daten können alle die Nutzer unseres Onlineangebotes betreffenden
        Angaben gehören, die im Rahmen der Nutzung und der Kommunikation
        anfallen. Hierzu gehören regelmäßig die IP-Adresse, die notwendig ist,
        um die Inhalte von Onlineangeboten an Browser ausliefern zu können, und
        alle innerhalb unseres Onlineangebotes oder von Webseiten getätigten
        Eingaben.
      </p>
      <p>
        <strong>E-Mail-Versand und -Hosting</strong>: Die von uns in Anspruch
        genommenen Webhosting-Leistungen umfassen ebenfalls den Versand, den
        Empfang sowie die Speicherung von E-Mails. Zu diesen Zwecken werden die
        Adressen der Empfänger sowie Absender als auch weitere Informationen
        betreffend den E-Mailversand (z.B. die beteiligten Provider) sowie die
        Inhalte der jeweiligen E-Mails verarbeitet. Die vorgenannten Daten
        können ferner zu Zwecken der Erkennung von SPAM verarbeitet werden. Wir
        bitten darum, zu beachten, dass E-Mails im Internet grundsätzlich nicht
        verschlüsselt versendet werden. Im Regelfall werden E-Mails zwar auf dem
        Transportweg verschlüsselt, aber (sofern kein sogenanntes
        Ende-zu-Ende-Verschlüsselungsverfahren eingesetzt wird) nicht auf den
        Servern, von denen sie abgesendet und empfangen werden. Wir können daher
        für den Übertragungsweg der E-Mails zwischen dem Absender und dem
        Empfang auf unserem Server keine Verantwortung übernehmen.
      </p>
      <p>
        <strong>Erhebung von Zugriffsdaten und Logfiles</strong>: Wir selbst
        (bzw. unser Webhostinganbieter) erheben Daten zu jedem Zugriff auf den
        Server (sogenannte Serverlogfiles). Zu den Serverlogfiles können die
        Adresse und Name der abgerufenen Webseiten und Dateien, Datum und
        Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über erfolgreichen
        Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers,
        Referrer URL (die zuvor besuchte Seite) und im Regelfall IP-Adressen und
        der anfragende Provider gehören.
      </p>
      <p>
        Die Serverlogfiles können zum einen zu Zwecken der Sicherheit eingesetzt
        werden, z.B., um eine Überlastung der Server zu vermeiden (insbesondere
        im Fall von missbräuchlichen Angriffen, sogenannten DDoS-Attacken) und
        zum anderen, um die Auslastung der Server und ihre Stabilität
        sicherzustellen.
      </p>
      <ul class="m-elements">
        <li>
          <p>
            <strong>Verarbeitete Datenarten:</strong> Inhaltsdaten (z.B.
            Texteingaben, Fotografien, Videos), Nutzungsdaten (z.B. besuchte
            Webseiten, Interesse an Inhalten, Zugriffszeiten),
            Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
          </p>
        </li>
        <li>
          <p>
            <strong>Betroffene Personen:</strong> Nutzer (z.B.
            Webseitenbesucher, Nutzer von Onlinediensten).
          </p>
        </li>
        <li>
          <p>
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f. DSGVO).
          </p>
        </li>
      </ul>
      <h2 id="m12">Löschung von Daten</h2>
      <p>
        Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen
        Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten
        Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen
        (z.B., wenn der Zweck der Verarbeitung dieser Daten entfallen ist oder
        sie für den Zweck nicht erforderlich sind).
      </p>
      <p>
        Sofern die Daten nicht gelöscht werden, weil sie für andere und
        gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung
        auf diese Zwecke beschränkt. D.h., die Daten werden gesperrt und nicht
        für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels-
        oder steuerrechtlichen Gründen aufbewahrt werden müssen oder deren
        Speicherung zur Geltendmachung, Ausübung oder Verteidigung von
        Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen
        oder juristischen Person erforderlich ist.
      </p>
      <p>
        Weitere Hinweise zu der Löschung von personenbezogenen Daten können
        ferner im Rahmen der einzelnen Datenschutzhinweise dieser
        Datenschutzerklärung erfolgen.
      </p>
      <ul class="m-elements"></ul>
      <h2 id="m15">Änderung und Aktualisierung der Datenschutzerklärung</h2>
      <p>
        Wir bitten Sie, sich regelmäßig über den Inhalt unserer
        Datenschutzerklärung zu informieren. Wir passen die Datenschutzerklärung
        an, sobald die Änderungen der von uns durchgeführten Datenverarbeitungen
        dies erforderlich machen. Wir informieren Sie, sobald durch die
        Änderungen eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder
        eine sonstige individuelle Benachrichtigung erforderlich wird.
      </p>
      <p>
        Sofern wir in dieser Datenschutzerklärung Adressen und
        Kontaktinformationen von Unternehmen und Organisationen angeben, bitten
        wir zu beachten, dass die Adressen sich über die Zeit ändern können und
        bitten die Angaben vor Kontaktaufnahme zu prüfen.
      </p>
      <h2 id="m10">Rechte der betroffenen Personen</h2>
      <p>
        Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die
        sich insbesondere aus Art. 15 bis 18 und 21 DSGVO ergeben:
      </p>
      <ul>
        <li>
          <strong>
            Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus
            Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung
            der Sie betreffenden personenbezogenen Daten, die aufgrund von Art.
            6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies
            gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
            Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um
            Direktwerbung zu betreiben, haben Sie das Recht, jederzeit
            Widerspruch gegen die Verarbeitung der Sie betreffenden
            personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen;
            dies gilt auch für das Profiling, soweit es mit solcher
            Direktwerbung in Verbindung steht.
          </strong>
        </li>
        <li>
          <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das
          Recht, erteilte Einwilligungen jederzeit zu widerrufen.
        </li>
        <li>
          <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung
          darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf
          Auskunft über diese Daten sowie auf weitere Informationen und Kopie
          der Daten entsprechend den gesetzlichen Vorgaben.
        </li>
        <li>
          <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den
          gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie
          betreffenden Daten oder die Berichtigung der Sie betreffenden
          unrichtigen Daten zu verlangen.
        </li>
        <li>
          <strong>
            Recht auf Löschung und Einschränkung der Verarbeitung:
          </strong>{" "}
          Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu
          verlangen, dass Sie betreffende Daten unverzüglich gelöscht werden,
          bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine
          Einschränkung der Verarbeitung der Daten zu verlangen.
        </li>
        <li>
          <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht,
          Sie betreffende Daten, die Sie uns bereitgestellt haben, nach Maßgabe
          der gesetzlichen Vorgaben in einem strukturierten, gängigen und
          maschinenlesbaren Format zu erhalten oder deren Übermittlung an einen
          anderen Verantwortlichen zu fordern.
        </li>
        <li>
          <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben ferner
          nach Maßgabe der gesetzlichen Vorgaben das Recht, bei einer
          Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres gewöhnlichen
          Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen
          Verstoßes, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie
          betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
        </li>
      </ul>
      <h2 id="m42">Begriffsdefinitionen</h2>
      <p>
        In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser
        Datenschutzerklärung verwendeten Begrifflichkeiten. Viele der Begriffe
        sind dem Gesetz entnommen und vor allem im Art. 4 DSGVO definiert. Die
        gesetzlichen Definitionen sind verbindlich. Die nachfolgenden
        Erläuterungen sollen dagegen vor allem dem Verständnis dienen. Die
        Begriffe sind alphabetisch sortiert.
      </p>
      <ul class="glossary">
        <li>
          <strong>Personenbezogene Daten:</strong> "Personenbezogene Daten“ sind
          alle Informationen, die sich auf eine identifizierte oder
          identifizierbare natürliche Person (im Folgenden "betroffene Person“)
          beziehen; als identifizierbar wird eine natürliche Person angesehen,
          die direkt oder indirekt, insbesondere mittels Zuordnung zu einer
          Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu
          einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren
          besonderen Merkmalen identifiziert werden kann, die Ausdruck der
          physischen, physiologischen, genetischen, psychischen,
          wirtschaftlichen, kulturellen oder sozialen Identität dieser
          natürlichen Person sind.{" "}
        </li>
        <li>
          <strong>Verantwortlicher:</strong> Als "Verantwortlicher“ wird die
          natürliche oder juristische Person, Behörde, Einrichtung oder andere
          Stelle, die allein oder gemeinsam mit anderen über die Zwecke und
          Mittel der Verarbeitung von personenbezogenen Daten entscheidet,
          bezeichnet.{" "}
        </li>
        <li>
          <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder ohne
          Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche
          Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff
          reicht weit und umfasst praktisch jeden Umgang mit Daten, sei es das
          Erheben, das Auswerten, das Speichern, das Übermitteln oder das
          Löschen.{" "}
        </li>
      </ul>
      <p class="seal">
        <a
          href="https://datenschutz-generator.de/?l=de"
          title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
          target="_blank"
        >
          Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas
          Schwenke
        </a>
      </p>
    </Wrapper>
  </Layout>
)

export default SecondPage
