import React, { useState, useEffect } from "react"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const IndexPage = () => {
  return (
    <Layout style={{ background: "#000" }}>
      <SEO title="Members" />
      <iframe src="https://vibrant-kare-a27ad1.netlify.com/gummy/" />
    </Layout>
  )
}

export default IndexPage
