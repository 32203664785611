import React from "react"
import Plx from "react-plx"

const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop)
    window.scrollTo(0, c - c / 8)
  }
}

// const phoneData = [
//   {
//     start: "self",
//     startOffset: 0,
//     duration: 600,
//     easing: [0.25, 0.1, 0.6, 1.5],
//     properties: [
//       {
//         startValue: 0,
//         endValue: 1,
//         property: "scale",
//       },
//     ],
//   },
// ]

const phoneData = [
  {
    start: "self",
    startOffset: 0,
    duration: 600,
    easing: [0.25, 0.1, 0.6, 1.5],
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "scale",
      },
    ],
  },
]

export default ({
  title = "",
  image,
  desc = "",
  reverse = false,
  animated = true,
  buttonComp,
  showPrice = false,
}) => (
  <Plx parallaxData={animated ? phoneData : []}>
    <div
      className={reverse ? "reverse" : ""}
      style={{
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        padding: 20,
        minHeight: 500,
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 400,
          minWidth: 320,
        }}
      >
        <div
          className="cover"
          style={{
            width: "80%",
            height: "80%",
            borderRadius: 15,
            boxShadow: "0 5px 30px rgba(0,0,0,0.2)",
            background: `url(${image})no-repeat center center`,
          }}
        ></div>
      </div>
      <div style={{ flex: 1, minWidth: 320, paddingLeft: 30 }}>
        <h3 style={{ fontWeight: 500 }}>{title}</h3>
        <p style={{ marginTop: "1rem", width: "100%" }}>{desc}</p>
        {buttonComp || (
          <button
            onClick={() => scrollToTop()}
            className="button"
            style={{ marginTop: "1rem" }}
          >
            {showPrice ? "Jetzt für 0.99€ spielen" : "Jetzt spielen"}
          </button>
        )}
      </div>
    </div>
  </Plx>
)
