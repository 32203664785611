import React from "react"
import { navigate } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Wrapper from "../../components/wrapper"

import ErrorLogo from "../../images/error.png"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Success" />
    <Wrapper>
      <div
        style={{
          width: "100%",
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          className="jump"
          style={{
            width: 100,
            height: 100,
            borderRadius: "50%",
            background: "#eb217c",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={ErrorLogo} alt="error" />
        </div>
        <h3 style={{ marginTop: 30 }}>Argh...</h3>
        <p>Da ist etwas schief gelaufen</p>

        <button
          className="button"
          style={{ margin: "1.2rem 0" }}
          onClick={() => {
            navigate("/payment")
          }}
        >
          Zurück
        </button>
      </div>
    </Wrapper>
  </Layout>
)

export default NotFoundPage
