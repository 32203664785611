import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Wrapper from "../../components/wrapper"
import GameItem from "../../components/gameitem"

const Games = [
  {
    name: "Breakoutz",
    slug: "breakoutz",
    image: require("../../images/breakzout.jpg"),
  },
  {
    name: "Gummy Blocks",
    slug: "gummy",
    image: require("../../images/gummyblocks.jpg"),
  },
  {
    name: "Candy Forest",
    slug: "candy",
    image: require("../../images/candyforest.png"),
  },
  {
    name: "Circle Wheel",
    slug: "CircleWheel",
    image: require("../../images/CircleWheel.jpg"),
  },
  {
    name: "Car Crossing",
    slug: "crossing",
    image: require("../../images/carcrossing.jpg"),
  },
  {
    name: "Hex Puzzle",
    slug: "puzzle",
    image: require("../../images/hexpuzzle.png"),
  },
]

const IndexPage = () => {
  const [playing, setPlay] = useState(false)

  return (
    <Layout>
      <SEO title="Members" />

      <Wrapper padding>
        <h2 style={{ textAlign: "center", fontWeight: 500, width: "100%" }}>
          Unsere Spiele
        </h2>
        {Games.map((game, index) => {
          return (
            <GameItem
              key={`game-${index}`}
              title={game.name}
              desc={game.desc}
              image={game.image}
              reverse={index % 2}
              animated={index < 2 ? false : true}
              buttonComp={
                <Link to={`/members/games/${game.slug}`} className="button">
                  Jetzt spielen
                </Link>
              }
            />
          )
        })}
      </Wrapper>

      {/* <Link to="/page-2/">Go to page 2</Link> */}
    </Layout>
  )
}

export default IndexPage
