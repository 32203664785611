import { ParallaxProvider } from "react-scroll-parallax"
import React from "react"

// export const wrapPageElement = ({ element, props }) => {
//     // props provide same data to Layout as Page element will get
//     // including location, data, etc - you don't need to pass it

//     return <Layout {...props}>{element}</Layout>
//   }

export const wrapRootElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <ParallaxProvider>{element}</ParallaxProvider>
}
